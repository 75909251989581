import React from 'react'
import '../static/css/Footer.css';
import insta from '../static/images/insta.png'

function Footer() {
    return (
        <div className="container-fluid">
            <footer>
            <div className="container">
                <p className="name">Gemesis Jewels</p>
                    <hr />
                    <div class="row">
                        <div class="col">
                            <h4>Phone</h4>
                            <p className="phone">(876) 973-6920</p>
                        </div>

                        <div class="col">
                            <h4>Email</h4>
                            <p className="email">gemesisjewels@cwjamaica.com</p>
                        </div>

                        <div class="col">
                            <h4>Social</h4>
                            <a href="https://www.instagram.com/gemesisjewelsja/?utm_medium=copy_link" target="_blank"><center><img className="ins" src={insta} height ="30px"alt="instagram" /></center></a>
                        </div>
                        
                        <hr />
                    <center>
                        <h6>Copyright &copy; Gemesis Jewels 2021.  All rights reserved.</h6>
                    </center>
            </div>
            </div>
            </footer>
            </div>
    )
}

export default Footer
