import React from 'react'
import logo from '../static/images/logo.jpeg'
import '../static/css/Header.css'

function Header() {
    return (
        <div className="container-fluid">
            <header>
                <center><img src={logo} height="150" width="160" /></center>
            </header>
        </div>
    )
}

export default Header
