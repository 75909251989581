import React from 'react'
import '../static/css/Nav.css';

function Nav() {
    return (
        <div class="topnav">
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                {/* <img src={logo} height="30" width="30" className="navbar-brand" alt=""></img> */}

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarText">
                    <ul className="nav justify-content-center">

                        <li className="nav-item">
                            <a className="nav-link" href="#Home">Home</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" href="#Jewelry">Jewelry</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" href="#Watches">Watches</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" href="#Perfumes">Perfumes</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}

export default Nav