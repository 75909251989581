import React from "react"
import './App.css';
import Header from '../src/components/Header'
import Nav from '../src/components/Nav';
import Footer from '../src/components/Footer';
import Landing from "./containers/Landing";

function App() {
  return (
    <div className="container-fluid">
        <Header />
        <Nav />
        <Landing />
        <Footer />
    </div>
  );
}

export default App;
