import React, { Component } from 'react'
import '../static/css/Landing.css'
import '../static/css/Cards.css'
import jewelry from '../static/images/jewelry.jpg'
import watch from '../static/images/watch.jpg'
import perfume from '../static/images/perfume.jpg'
import samuel from '../static/jewelry images/samuel.jpg'
import tungsten from '../static/jewelry images/tungsten.png'
import unode50 from '../static/jewelry images/unode50.jpg'
import bering from '../static/watch images/bering.png'
import bulova from '../static/watch images/bulova.jpg'
import twsteel from '../static/watch images/twsteel.png'
import ea from '../static/watch images/ea.png'
import fossil from '../static/watch images/fossil.jpg'
import swissmilitary from '../static/watch images/swissmilitary.jpg'
import ch from '../static/perfume images/ch.jpg'
import chanel from '../static/perfume images/chanel.jpg'
import givenchy from '../static/perfume images/givenchy.jpg'
import jimmychoo from '../static/perfume images/jimmychoo.png'
import rl from '../static/perfume images/rl.jpg'
import versace from '../static/perfume images/versace.png'

export default class Landing extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div class="col xs lg=4">
                        <p className="cat1">JEWELRY</p>
                    </div>
                    <div class="col xs lg=4">
                        <p className="cat2">WATCHES</p>
                    </div>
                    <div class="col xs lg=4">
                        <p className="cat3">PERFUMES</p>
                    </div>
                </div>

                <div className="images container" id="Home">
                    <div className="row">
                        <div className="jewelry col">
                            <img src={jewelry} height="auto" width="90%" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="watch col-md-6 px-0 hidden">
                            <img src={watch} width="auto" height="100%" />
                        </div>
                        <div className="perfume col-md-4 px-0 hidden">
                            <img src={perfume} width="auto" height="100%" />
                        </div>
                    </div>
                </div>

                <center><h2 className="brands">JEWELRY</h2></center>
                <div className="jewelry-container" id="Jewelry">
                    <div class="row">
                        <div class="col xs lg=4">
                            <div class="card">
                                <img src={samuel} width="100%" height="auto" />
                            </div>
                        </div>

                        <div class="col xs lg=4">
                            <div class="card">
                                <img src={tungsten} width="100%" height="auto" />
                            </div>
                        </div>

                        <div class="col xs lg=4">
                            <div class="card">
                                <img src={unode50} width="100%" height="auto" />
                            </div>
                        </div>

                    </div>
                </div>

                <center><h2 className="brands">WATCHES</h2></center>
                <div className="watch-container" id="Watches">
                    <div class="row">
                        <div class="col xs lg=4">
                            <div class="card">
                                <img src={bering} width="100%" height="auto" />
                            </div>
                        </div>

                        <div class="col xs lg=4">
                            <div class="card">
                                <img src={bulova} width="100%" height="auto" />
                            </div>
                        </div>

                        <div class="col xs lg=4">
                            <div class="card">
                                <img src={twsteel} width="100%" height="auto" />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col xs lg=4">
                            <div class="card">
                                <img src={ea} width="100%" height="auto" />
                            </div>
                        </div>

                        <div class="col xs lg=4">
                            <div class="card">
                                <img src={fossil} width="100%" height="auto" />
                            </div>
                        </div>

                        <div class="col xs lg=4">
                            <div class="card">
                                <img src={swissmilitary} width="100%" height="auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <center><h2 className="brands">PERFUMES</h2></center>
                <div className="perfume-container" id="Perfumes">
                    <div class="row">
                        <div class="col xs lg=4">
                            <div class="card">
                                <img src={ch} width="100%" height="auto" />
                            </div>
                        </div>

                        <div class="col xs lg=4">
                            <div class="card">
                                <img src={chanel} width="100%" height="auto" />
                            </div>
                        </div>

                        <div class="col xs lg=4">
                            <div class="card">
                                <img src={givenchy} width="100%" height="auto" />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col xs lg=4">
                            <div class="card">
                                <img src={jimmychoo} width="100%" height="auto" />
                            </div>
                        </div>

                        <div class="col xs lg=4">
                            <div class="card">
                                <img src={rl} width="100%" height="auto" />
                            </div>
                        </div>

                        <div class="col xs lg=4">
                            <div class="card">
                                <img src={versace} width="100%" height="auto" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <center>
                        <h5>ADDRESS</h5>
                        <p className="address">The Lobby Shop<br />Gran Bahia Principe Resort and Spas Runaway Bay St.Ann, Jamaica, W.I.</p>
                    </center>
                </div>
            </div>
        )
    }
}
